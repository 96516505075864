export default {
  data() {
    return {
      title: document.title, //浏览器标题
      titleScroll: '', //滚动标题
      icon: '/favicon.ico', //浏览器logo
      isBlinking: false,
      intervalId: null,
      isScroll: false, //是否滚动
      scrollInterval: null,
      isNotice: true, //是否发送通知
    };
  },
  mounted() {},
  beforeDestroy() {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
    }
    console.log('beforeDestroy------------', this.scrollInterval);
  },
  methods: {
    handleScroll(title) {
      console.log('handleScroll-------------', title);
      this.isScroll = false;
      console.log(this.scrollInterval, 'this.scrollInterval');
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
        this.scrollInterval = null;
      }
      console.log(this.scrollInterval, 'this.scrollInterval');
      if (!this.isScroll && !this.scrollInterval) {
        this.startScroll(title);
      }
      // if (!this.isBlinking) {
      // this.startBlinking();
      // }
      // if (this.isNotice) {
      //   this.handleNotice(title);
      // }
    },
    startScroll(title) {
      console.log(title, 'startScroll-------------', this.scrollInterval);
      this.isScroll = true;
      this.titleScroll = title;
      let counter = 0;
      console.log(this.titleScroll, title, document.title, 'title');
      this.scrollInterval = setInterval(() => {
        // 修改document.title
        document.title =
          this.titleScroll.substring(counter) + this.titleScroll.substring(0, counter);
        counter++;
        if (counter > this.titleScroll.length) counter = 0;
      }, 500);
      console.log(this.titleScroll, title, document.title, 'title');
    },
    stopScroll(title) {
      console.log('stopScroll-----------', this.scrollInterval);
      this.isScroll = false;
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
        this.scrollInterval = null;
      }
      document.title = title;
      console.log(title, document.title, this.scrollInterval, 'title');
    },
    setTitleIcon(icon) {
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = icon; // 图片放public目录
      document.getElementsByTagName('head')[0].appendChild(link);
      // document.getElementsByTagName('title')[0].innerText = '动态修改title的值';
    },
    handleNotice(title) {
      console.log('handleNotice');
      let that = this;
      if (!('Notification' in window)) {
        alert('此浏览器不支持桌面通知！');
      }
      Notification.requestPermission().then(function (result) {
        console.log(result, 'result');
        if (result === 'denied') {
          console.log('被拒绝');
          return;
        }
        if (result === 'default') {
          console.log('默认');
          return;
        }
        that.notifyMsg(title);
      });
    },
    notifyMsg(body) {
      console.log('notifyMsg');
      //通知
      const title = 'tt智能投放助手';
      const options = {
        body: body, // 通知主体
        data: '111111111',
        icon: '@/src/assets/icon/PLACEMENT_TIKTOK.png', // 通知图标
      };
      let notification = new Notification(title, options);
      notification.onclick = () => {
        window.focus();
        notification.close(); //关闭通知
      };
      this.isNotice = true;
    },
    startBlinking() {
      this.isBlinking = true;
      this.intervalId = setInterval(() => {
        if (document.title === this.title) {
          document.title = '🔴新消息通知';
          this.setTitleIcon('/favicon1.ico');
        } else {
          document.title = this.title;
          this.setTitleIcon('/favicon.ico');
        }
        // document.title = document.title === this.title ? '🔴新消息通知' : this.title;
      }, 1000);
    },
    stopBlinking() {
      this.isBlinking = false;
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      document.title = this.title;
    },
  },
};
