import {
    checkAccountStatus
} from "@/api/common/account";
export default {
    methods: {
        // 检查广告帐号状态-启用/禁用
        checkAccountStatusGlobal(accountId) {
            this.$showLoading();
            return checkAccountStatus({
                id: accountId,
                checkPage:true,
            }).then(res => {
                this.$hideLoading();
                return res
            });
        },
    }
}